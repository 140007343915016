<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Categories" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary" :to="{ name: 'Create Category' }">
            Add New Category
          </md-button>
        </div>
      </div>
      <md-table
        v-model="categories.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <!-- <md-button class="md-primary button-icon" disabled>
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button> -->
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
          <md-table-cell md-label="Category">{{
            resolveCat(item)
          }}</md-table-cell>
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium">
              <div v-if="hasPermission">
                <md-button
                  :disabled="updating || deleting"
                  md-menu-trigger
                  class="bggreen"
                  >Menu</md-button
                >
              </div>

              <md-menu-content>
                <router-link
                  tag="md-menu-item"
                  :to="{ name: 'Edit Category', params: { id: item.id } }"
                >
                  Edit
                </router-link>
                <md-menu-item @click="remove(item.id)">Delete</md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="categories.mdPage"
        :records="categories.mdCount"
        :per-page="categories.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");
export default {
  name: "User",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getCategories", "deleteCategory"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getCategories(data);
    },
    async submitSearch() {
      await this.getCategories({ keyword: this.search });
    },
    resolveCat(item) {
      let title;
      if (item.parent_id == 0) {
        title = "ROOT";
      } else {
        title = item.parent.title;
      }
      return item.parent_id + " | " + title;
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      const conf = confirm("Are you sure?");
      if (conf) {
        this.deleting = true;
        await this.deleteCategory(id);
        this.getCategories();
        this.deleting = false;
        this.toast("success", "Category deleted");
      }
    },
  },
  created() {},
  mounted() {
    this.getCategories();
  },
  computed: {
    ...mapGetters(["categories", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
